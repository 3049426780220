<template>
  <div class="game-container">
    Waiting for page publish
  </div>
</template>

<script>
export default {
  name: "FishkeepingDemo",
};
</script>

<style scoped>
.game-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
