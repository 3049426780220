<template>
  <div>
    <div v-if="loading" class="d-flex justify-content-center align-items-center flex-column">
      <div class="spinner-border text-primary" role="status"></div>
      <p class="mt-2">Fetching article details, please wait...</p>
    </div>

    <!-- Article details -->
    <div v-else>
      <h1>{{ article.title }}</h1>
      <p><strong>Author:</strong> {{ article.author }}</p>
      <p><strong>Created At:</strong> {{ formatDate(article.createdAt) }}</p>
      <p><strong>Description:</strong> {{ article.description }}</p>
      <QuillEditor :content="article.content" />
      <button @click="goBack" class="btn btn-secondary mt-3">Back</button>
    </div>
  </div>
</template>

<script>
import QuillEditor from './QuillEditor.vue';
import AxiosWrapper from '../components/AxiosWrapper.js';

export default {
  name: 'ArticleDetailPage',
  components: {
    QuillEditor,
  },
  data() {
    return {
      article: {},
      loading: true,
    };
  },
  mounted() {
    this.fetchArticle();
  },
  methods: {
    async fetchArticle() {
      const vanityUrl = this.$route.params.vanityUrl;
      try {
        this.article = await AxiosWrapper.SendGetRequest(`articles/${vanityUrl}`, 1); // Retry once before switching to backup
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error('Failed to fetch article details from both APIs:', error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    goBack() {
      this.$router.push('/articles');
    }
  }
};
</script>

<style scoped>
.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>
