// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/custom.css'; // Import your custom styles

const app = createApp(App);
app.use(router);
app.mount('#app');
