<template>
  <div class="light-background-box">
    <div ref="editorContainer"></div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import the Quill styles

export default {
  name: 'QuillEditor',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  mounted() {
    // Initialize Quill editor
    this.quill = new Quill(this.$refs.editorContainer, {
      theme: '',
      readOnly: true, // Set editor to read-only mode
      modules: {
        toolbar: false, // Disable the toolbar if you don't want it
      },
    });

    // Set the HTML content
    this.quill.root.innerHTML = this.content;
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
