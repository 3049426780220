<template>
  <div>
    <!-- Message to prompt the user to switch to landscape -->
    <div class="landscape-required" v-if="isPortrait">
      Please rotate your device to landscape mode for the best experience.
    </div>
      <div class="d-flex align-items-center">
        <button @click="goBack" class="btn btn-secondary mt-3 me-3">Back</button>
        <h2 class="mt-3">Şeptică</h2>
      </div>
      <!-- Game iframe maintaining a 1152x648 (16:9) aspect ratio -->
      <div v-if="!isPortrait" class="iframe-container">
        <iframe frameborder="0" src="https://itch.io/embed-upload/11888829?color=69caca" allowfullscreen="" width="1152" height="668"><a href="https://gab16.itch.io/septica">Play Septica on itch.io</a></iframe>
      </div>
  </div>
</template>

<script>
export default {
  name: "SepticaDemo",
  data() {
    return {
      isPortrait: false, // Tracks whether the device is in portrait mode
    };
  },
  methods: {
    checkOrientation() {
      this.isPortrait = window.innerHeight > window.innerWidth;
    },
    goBack() {
      this.$router.push('/demos');
    }
  },
  mounted() {
    // Check orientation when component is mounted
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation); // Listen for orientation changes
  },
  beforeUnmount() {
    // Cleanup the event listener when the component is unmounted
    window.removeEventListener('resize', this.checkOrientation);
  },
};
</script>

<style scoped>
.iframe-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (9/16 = 0.5625, so padding-top = 56.25%) */
  overflow: hidden;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Style for the message prompting landscape mode */
.landscape-required {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-size: 1.5em;
  background-color: #ffcccc; /* Light background color to grab attention */
  padding: 20px;
}
</style>
