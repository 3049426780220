<template>
  <div>
    <h1>Welcome!</h1>
    <small>And feel free to surf around</small>
    <hr />

    <p>
      This website serves as both a tech demo itself, and a place to host future tech demos or articles related to concepts in the areas of web
      development, cloud operations, and perhaps some game development.
    </p>
    <p>
      <small>(The site started out heavily reliant on the cloud, which inspired the cloud theme for the website - it is now a mix of on premise and cloud services)</small>
    </p>
    <br />

    <p>The public site relies on three main components:</p>
    <ul>
      <li>A <strong>Vue.js</strong> frontend, deployed as a static web app.</li>
      <li>A <strong>.NET backend</strong> that serves data via an API </li>
      <li>A <strong>MS SQL</strong> database.</li>
    </ul>

    <p>Both repositories have their own CI/CD pipelines that automatically deploy changes when new updates are merged
      into the master branch.</p>
    <hr />

    <h4>Technologies Used</h4>
    <div class="cards-scroll-container">
      <div class="tech-card" v-for="technology in cards" :key="technology.title">
        <a :href="technology.link" target="_blank">
          <img :src="technology.image" :alt="`${technology.title} Logo`" />
          <div class="tech-info">
            <h5>{{ technology.title }}</h5>
            <p>{{ technology.description }}</p>
          </div>
        </a>
      </div>
    </div>

    <hr />
    <h4>Articles</h4>
    <p>
      The articles page will contain dev logs, ideas on how to do small features
      or how to troubleshoot some problems I've had that didn't have readily
      accessible solutions online.
    </p>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      cards: [
        {
          title: "Microsoft Azure",
          image: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Microsoft_Azure_Logo.svg",
          link: "https://azure.microsoft.com",
          description: "Cloud platform for deploying and managing web apps",
        },
        {
          title: "Vue.js",
          image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Vue.js_Logo_2.svg/512px-Vue.js_Logo_2.svg.png?20170919082558",
          link: "https://vuejs.org",
          description: "A progressive JavaScript framework for building user interfaces",
        },
        {
          title: ".NET",
          image: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/.NET_Core_Logo.svg/2048px-.NET_Core_Logo.svg.png",
          link: "https://dotnet.microsoft.com",
          description: "A developer platform for building all types of applications",
        },
        {
          title: "MS SQL Server",
          image: "https://upload.wikimedia.org/wikipedia/commons/6/69/Microsoft_SQL_Server_Logo.png",
          link: "https://www.microsoft.com/sql-server",
          description: "A relational database management system by Microsoft",
        },
        {
          title: "GitHub Desktop",
          image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Github-desktop-logo-symbol.svg/128px-Github-desktop-logo-symbol.svg.png?20200316183539",
          link: "https://github.com/apps/desktop",
          description: "An intuitive but efficient Git GUI tool used in versino control",
        },
        {
          title: "Azure Repos",
          image: "https://cdn.freelogovectors.net/wp-content/uploads/2022/03/azure_repos_logo_freelogovectors.net_-360x400.png",
          link: "https://azure.microsoft.com/en-us/products/devops/repos",
          description: "Platform for storing and managing Git repositories used in version control",
        },
        {
          title: "Godot Engine",
          image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Godot_icon.svg/600px-Godot_icon.svg.png?",
          link: "https://godotengine.org/",
          description: "An open-source game engine for 2D and 3D games",
        },
      ],
    };
  },
};
</script>