<template>
  <div>
    <h1>Articles</h1>
    <hr />
    <ul v-if="articles.length">
      <li v-for="article in articles" :key="article.id" @click="goToArticle(article.vanityUrl)" class="light-background-box"  style="cursor: pointer;">
        <h2>{{ article.title }}</h2>
        <small>{{ article.description }}</small>
        <p><strong>Author:</strong> {{ article.author }}</p>
        <p><strong>Created At:</strong> {{ formatDate(article.createdAt) }}</p>
      </li>
    </ul>
    <div v-else>
      <div class="d-flex justify-content-center align-items-center flex-column">
        <div class="spinner-border text-primary" role="status"></div>
        <p class="mt-2">Fetching data, please wait...</p>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosWrapper from '../components/AxiosWrapper.js';

export default {
  name: 'ArticlesPage',
  data() {
    return {
      articles: [], // Holds the articles fetched from the API
    };
  },
  created() {
    this.fetchArticles(); // Fetch articles when the component is created
  },
  methods: {
    async fetchArticles() {
      try {
        this.articles = await AxiosWrapper.SendGetRequest('articles', 1); // Retry once if needed
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    },
    goToArticle(vanityUrl) {
      this.$router.push(`/articles/${vanityUrl}`);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
</style>
