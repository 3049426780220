<template>
  <div>
    <h1>Contact Us</h1>
    <hr />
    <p>Email: contact@gab16.com</p>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
};
</script>

<style scoped>
.contact {
  text-align: center;
  margin-top: 50px;
}
</style>
