<template>
  <div id="app">
  <div class="cloud-container">
  
  <div class="cloud cloud1"></div>
  <div class="cloud cloud2"></div>
  <div class="cloud cloud3"></div>
  <div class="cloud cloud4"></div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <router-link class="navbar-brand" to="/">
    <img src="@/assets/logo.png" alt="Logo" class="navbar-logo"  /> 
    </router-link>
      <!-- Hamburger menu button (Bootstrap toggler) -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- Navbar items collapse inside the hamburger menu -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/articles">Articles</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/demos">Demos</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contact">Contact</router-link>
          </li>
        </ul>
      </div>
    </nav>

    <div class="content-container">
      <router-view />
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
